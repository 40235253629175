require("es6-promise").polyfill();
require("url-polyfill");
window.axios = require("axios");

import Helpers from "../Helpers";

$(function () {
    class RecommendMigrate {
        /**
         * Constructor for Recommend Migrate
         */
        constructor() {
            this.helpers = new Helpers();
            this.sAppUrl = this.helpers.getAppUrl("recommend-migrate");
            this.sCssUrl = `${this.sAppUrl}/css/front/recommend-migrate.css`;
            $("head").append(`<link rel="stylesheet" href="${this.sCssUrl}" type="text/css" />`);
            this.unavailableAgent = ["Byapps", "smartskin", "powerapp", "makeshopapp", "app:denps", "WISAAPP"];
            this.oPopupLayer = null;
            this.oAlertLayer = null;
            this.sMallId = EC_FRONT_JS_CONFIG_MANAGE.sMallId;
            this.sShopNo = typeof EC_SDE_SHOP_NUM !== "undefined" ? EC_SDE_SHOP_NUM : 1;
            this.getRecommendMigrateData();
        }

        /**
         * Method to get recommend migrate data from public api
         * @returns {Promise<void>}
         */
        async getRecommendMigrateData() {
            let oRequest = {
                method: "GET",
                url: `${this.sAppUrl}/api/front/${this.sMallId}/recommend-migrate?shop_no=${this.sShopNo}`,
            };

            let oResponse = await this.helpers.apiRequest(oRequest);
            this.aPlusappSettings = oResponse.data.data.plusapp_settings;
            this.aTextDisplay = oResponse.data.data.text_display;
            this.initialize();
        }

        /**
         * Method to initialize html content
         * If useragent is unsupported, logic is not continued
         */
        initialize() {
            if (this.isUnsupportedAgent() === false || this.aPlusappSettings["service_available"] === false) {
                return;
            }
            this.renderHtmlContent();
            this.oPopupLayer.show();
            this.oAlertLayer.show();
        }

        /**
         * Method for adding event listener to recommend migrate banner
         */
        addEventListener() {
            this.oAlertLayer.find(".btnInstall").click((oEvent) => {
                oEvent = oEvent || window.oEvent;
                oEvent.preventDefault ? oEvent.preventDefault() : (oEvent.returnValue = false);
                return this.helpers.openStoreOnlyWithLogs("recommend-migrate", this.sMallId, this.sAppUrl, this.aPlusappSettings);
            });
        }

        /**
         * Method to render html dom object for recommend migrate banner
         * Will also declare oPopupLayer and oAlertLayer to their dom counterparts
         */
        renderHtmlContent() {
            let oTextHtml = `
                <div class="xans-element- xans-cafe24plusapp xans-cafe24plusapp-recommendmigrate">
                    <div id="mCafe24AppMigrate">
                        <div class="dimmed"></div>
                        <div class="layerAlert" style="display: none;">
                            <p class="message">${this.aTextDisplay.update_recommend_text}</p>
                            <div class="btnBox">
                                <a href="#" class="btnInstall">${this.aTextDisplay.alert_confirm_button}</a>
                            </div>
                        </div>
                    </div>
                </div>
            `;
            $("body").prepend(oTextHtml);
            this.oPopupLayer = $("#mCafe24AppMigrate");
            this.oAlertLayer = this.oPopupLayer.find(".layerAlert");
            this.addEventListener();
        }

        /**
         * Check if the app is supported
         * @return {boolean}
         */
        isUnsupportedAgent() {
            if (this.helpers.isAndroid() || this.helpers.isIos()) {
                let agentList = this.unavailableAgent.join("|");
                let pattern = new RegExp(agentList, "i");
                return pattern.test(this.helpers.userAgent);
            }
            return false;
        }
    }

    new RecommendMigrate();
});
